import { useEffect, useState } from "react"

import {
	Flex,
	Heading,
	Button,
	Select,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Input,
	FormControl,
	FormLabel,
	Alert,
	AlertIcon,
	Box,
	AlertTitle,
	AlertDescription,
	CloseButton,
	FormHelperText,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react"

import { Table } from "antd"
import { ColumnsType } from "antd/es/table"

import useUserStore, { type Client } from "../stores/userStore"

import "./Clients.css"

export default function Clients() {
	const userStore = useUserStore()

	const [clients, setClients] = useState([] as Client[])
	const [company, setCompany] = useState("")
	const [companyId, setCompanyId] = useState("")

	const [isEditing, setIsEditing] = useState(false)

	const {
		isOpen: isDrawerOpen,
		onOpen: onDrawerOpen,
		onClose: onDrawerClose,
	} = useDisclosure()
	const {
		isOpen: isAlertOpen,
		onOpen: onAlertOpen,
		onClose: onAlertClose,
	} = useDisclosure()
	const {
		isOpen: isDeleteModalOpen,
		onOpen: onDeleteModalOpen,
		onClose: onDeleteModalClose,
	} = useDisclosure()

	function updateClients() {
		userStore.getClients().then((data: any) => {
			setClients(
				data.map((client: Client) => ({
					key: client.id,
					company: client.company,
					createdAt: new Date(client.createdAt).toLocaleDateString("fr-FR", {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
						hour: "2-digit",
						minute: "2-digit",
					}),
					commandsCount: client.commandsCount,
					lastCommandDate: client.lastCommandDate
						? new Date(client.lastCommandDate).toLocaleDateString("fr-FR", {
								day: "2-digit",
								month: "2-digit",
								year: "numeric",
						  })
						: "Aucune commande",
					knotsCount: client.knotsCount,
				}))
			)
		})
	}

	function createClient() {
		userStore
			.postClient({ company })
			.then(() => {
				updateClients()
				onDrawerClose()

				onAlertOpen()
				setTimeout(onAlertClose, 5000)

				setCompany("")
			})
			.catch((err) => {
				console.error(err)
			})
	}

	function editClient() {
		userStore
			.putClient(companyId, { company })
			.then(() => {
				updateClients()
				onDrawerClose()
				setIsEditing(false)
				setCompany("")
			})
			.catch((err) => {
				console.error(err)
			})
	}

	useEffect(updateClients, [userStore])

	const columns: ColumnsType<any> = [
		{
			title: "Entreprise",
			dataIndex: "company",
			sorter: (a: Client, b: Client) => a.company.localeCompare(b.company),
		},
		{
			title: "Date de création",
			dataIndex: "createdAt",
			sorter: (a, b) =>
				new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
			defaultSortOrder: "descend",
		},
		{
			title: "Dernière commande",
			dataIndex: "lastCommandDate",
			sorter: (a, b) =>
				new Date(a.lastCommandDate).getTime() -
				new Date(b.lastCommandDate).getTime(),
		},
		{
			title: "Nombre de commande",
			dataIndex: "commandsCount",
			sorter: (a: Client, b: Client) => a.commandsCount - b.commandsCount,
		},
		{
			title: "Nombre total de noeuds",
			dataIndex: "knotsCount",
			sorter: (a: Client, b: Client) => a.knotsCount - b.knotsCount,
		},
		{
			title: "Options",
			dataIndex: "key",
			render: (text, record, index) => (
				<Select
					placeholder="Ouvrir"
					size="sm"
					w="24"
					onChange={(event) => {
						userStore
							.getClient(parseInt(record.key))
							.then((client: Client) => {
								setCompanyId(record.key)
								setCompany(client.company)
								if (event.target.value === "Modifier le client") {
									setIsEditing(true)
									onDrawerOpen()
								} else {
									onDeleteModalOpen()
								}
								event.target.selectedIndex = 0
							})
					}}
				>
					<option>Modifier le client</option>
					<option>Supprimer le client</option>
				</Select>
			),
		},
	]

	return (
		<>
			<Flex direction="column" gap="16px">
				<Flex justifyContent="space-between" alignItems="center">
					<Heading>Liste des clients</Heading>
					<Button
						colorScheme="teal"
						size="sm"
						onClick={() => {
							onDrawerOpen()
							setIsEditing(false)
							setCompany("")
						}}
					>
						Ajouter un client
					</Button>
				</Flex>

				<Table columns={columns} dataSource={clients} className="Table"></Table>
			</Flex>

			<Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						{isEditing ? "Modifier un" : "Ajouter un nouveau"} client
					</DrawerHeader>

					<DrawerBody>
						<FormControl>
							<FormLabel>Nom du client</FormLabel>
							<Input
								placeholder="Saisissez le nom du client"
								onChange={(e) => setCompany(e.target.value)}
								value={company}
							/>
							<FormHelperText>
								Le nom du client doit être compris entre 2 et 50
								caractères et ne doit contenir que des lettres et des
								chiffres.
							</FormHelperText>
						</FormControl>
					</DrawerBody>

					<DrawerFooter display="flex" gap="8px">
						<Button variant="outline" onClick={onDrawerClose}>
							Annuler
						</Button>
						<Button
							colorScheme="teal"
							onClick={(x) => {
								if (isEditing) {
									editClient()
								} else {
									createClient()
								}
							}}
							isDisabled={
								company.length < 2 ||
								company.length > 50 ||
								!company.match(/^[a-zA-Z0-9 ]*$/)
							}
						>
							{isEditing ? "Modifier" : "Ajouter"} le client
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>

			<Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Suppression du client</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						Êtes-vous vraiment sûr de vouloir supprimer le client {company} ?
					</ModalBody>
					<ModalFooter display="flex" gap="4">
						<Button colorScheme="gray" onClick={onDeleteModalClose}>
							Annuler
						</Button>
						<Button
							colorScheme="red"
							onClick={() => {
								userStore.deleteClient(companyId).then(updateClients)
								onDeleteModalClose()
							}}
						>
							Supprimer
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{isAlertOpen && (
				<Alert
					status="success"
					position="fixed"
					right="8px"
					bottom="8px"
					display="flex"
					width="fit-content"
				>
					<AlertIcon />
					<Box>
						<AlertTitle>Nouveau client</AlertTitle>
						<AlertDescription>
							La fiche client a bien été créée
						</AlertDescription>
					</Box>
					<CloseButton
						alignSelf="flex-start"
						position="relative"
						right={-1}
						top={-1}
						onClick={onAlertClose}
					/>
				</Alert>
			)}
		</>
	)
}
