import { useState, useEffect } from "react"

import { Link } from "react-router-dom"

import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Badge,
	Box,
	Button,
	CloseButton,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Select,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react"

import { Table } from "antd"
import { ColumnsType } from "antd/es/table"

import useUserStore, {Client, Command, CommandPost, CommandView} from "../stores/userStore"

import "./Commands.css"

export default function Commands() {
	const userStore = useUserStore()

	const [commandPost, setCommandPost] = useState({} as CommandPost)
	const [commands, setCommands] = useState([] as CommandView[])
	const [clients, setClients] = useState([] as Client[])

	const [isStockAvailable, setIsStockAvailable] = useState(true)

	const {
		isOpen: isDrawerOpen,
		onOpen: onDrawerOpen,
		onClose: onDrawerClose,
	} = useDisclosure()
	const {
		isOpen: isAlertOpen,
		onOpen: onAlertOpen,
		onClose: onAlertClose,
	} = useDisclosure()

	function updateCommands() {
		userStore.getCommands().then((data) => {
			const revampedData: CommandView[] = data.map((x: Command) => ({
				key: x.id,
				company: x.client.company,
				order_number: x.order_number,
				knot_number: x.knot_number,
				remaining_knots: x.remaining_knots,
				knot_size: x.knot_size,
				knot_color: x.knot_color,
				status: x.status,
				createdAt: new Date(x.createdAt).toLocaleDateString("fr-FR"),
			}))

			setCommands(revampedData)
		})
	}

	function createCommand() {
		userStore
			.postCommand(commandPost)
			.then((result: any) => {
				if (result.error === "Stock not available") throw new Error()
				setIsStockAvailable(true)
				onAlertOpen()
				setTimeout(onAlertClose, 5000)
				onDrawerClose()
				updateCommands()
				setCommandPost({} as CommandPost)
			})
			.catch((_) => {
				setIsStockAvailable(false)
			})
	}

	function isFormValid() {
		return (
			["Petit", "Moyen", "Grand"].includes(commandPost.knot_size) &&
			["Bleu", "Rouge", "Beige", "Vert"].includes(commandPost.knot_color) &&
			commandPost.knot_number > 0 &&
			commandPost.order_number &&
			commandPost.clientId
		)
	}

	useEffect(updateCommands, [userStore])
	useEffect(() => {
		userStore.getClients().then((data: any) => setClients(data))
	}, [userStore])

	const columns: ColumnsType<CommandView> = [
		{
			title: "Entreprise",
			dataIndex: "company",
			key: "company",
			sorter: (a, b) => a.company.localeCompare(b.company),
		},
		{
			title: "Numéro de commande",
			dataIndex: "order_number",
			key: "order_number",
			sorter: (a, b) => a.knot_number - b.knot_number,
		},
		{
			title: "Date d'enregistrement",
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: (a, b) =>
				new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
		},
		{
			title: "Nb de noeuds total",
			dataIndex: "knot_number",
			key: "knot_number",
			sorter: (a, b) => a.knot_number - b.knot_number,
		},
		{
			title: "Nb de noeuds restants",
			dataIndex: "remaining_knots",
			key: "remaining_knots",
			sorter: (a, b) => a.remaining_knots - b.remaining_knots,
		},
		{
			title: "Taille des noeuds",
			dataIndex: "knot_size",
			key: "knot_size",
			sorter: (a, b) => a.knot_size.localeCompare(b.knot_size),
		},
		{
			title: "Couleur des noeuds",
			dataIndex: "knot_color",
			key: "knot_color",
			sorter: (a, b) => a.knot_color.localeCompare(b.knot_color),
		},
		{
			title: "Statut",
			dataIndex: "status",
			key: "status",
			sorter: (a, b) => a.status.localeCompare(b.status),
			render: (_, record, __) => {
				switch (record.status) {
					case "PENDING":
						return <Badge colorScheme="blue">EN ATTENTE</Badge>
					case "REGISTERED":
						return <Badge colorScheme="green">ENREGISTRÉ</Badge>
					case "DISABLED":
						return <Badge colorScheme="gray">DÉSACTIVÉ</Badge>
				}
			},
		},
		{
			title: "Options",
			dataIndex: "id",
			key: "id",
			render: (_, record, __) => (
				<Link to={`/commands/${record.company}/${record.order_number}`}>
					<Button variant="ghost" size="sm" w="24">
						Ouvrir
					</Button>
				</Link>
			),
		},
	]

	return (
		<>
			<Flex direction="column" gap="16px">
				<Flex justifyContent="space-between" alignItems="center">
					<Heading>Liste des commandes</Heading>
					<Button colorScheme="teal" size="sm" onClick={onDrawerOpen}>
						Ajouter une commande
					</Button>
				</Flex>

				<Table columns={columns} dataSource={commands} className="Table"></Table>
			</Flex>

			<Drawer
				isOpen={isDrawerOpen}
				placement="right"
				onClose={function () {
					onDrawerClose()
					setIsStockAvailable(true)
				}}
				size="lg"
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Créer une commande</DrawerHeader>

					<DrawerBody>
						<Stack spacing="16px">
							<FormControl>
								<Heading size="md">Informations</Heading>

								<Stack spacing="16px">
									<Stack spacing="4px">
										<FormLabel>
											Saisissez le numéro de commande
										</FormLabel>
										<Input
											placeholder="1387654"
											type="number"
											onChange={(e) =>
												setCommandPost({
													...commandPost,
													order_number: parseInt(
														e.target.value
													),
												})
											}
										></Input>
									</Stack>

									<Stack spacing="4px">
										<FormLabel>Nom du client</FormLabel>
										<Select
											placeholder="Sélectionnez le nom du client"
											onChange={(e) =>
												setCommandPost({
													...commandPost,
													clientId: parseInt(e.target.value),
												})
											}
										>
											{clients.map((x) => (
												<option key={x.id} value={x.id}>
													{x.company}
												</option>
											))}
										</Select>
									</Stack>
								</Stack>
							</FormControl>

							<FormControl isInvalid={!isStockAvailable}>
								<Stack spacing="16px">
									<Stack spacing="4px">
										<Heading size="md">Noeuds commandés</Heading>
										<Text color="gray.500">
											Si la commande comporte plusieurs types de
											noeuds, veuillez créer plusieurs commandes
										</Text>
									</Stack>

									<Stack spacing="4px">
										<FormLabel>Taille des noeuds</FormLabel>
										<Select
											placeholder="Sélectionnez la taille des noeuds"
											onChange={(e) =>
												setCommandPost({
													...commandPost,
													knot_size: e.target.value as
														| "Petit"
														| "Moyen"
														| "Grand",
												})
											}
										>
											<option>Petit</option>
											<option>Moyen</option>
											<option>Grand</option>
										</Select>
									</Stack>

									<Stack spacing="4px">
										<FormLabel>Couleur des noeuds</FormLabel>
										<Select
											placeholder="Sélectionnez la couleur des noeuds"
											onChange={(e) =>
												setCommandPost({
													...commandPost,
													knot_color: e.target.value as
														| "Bleu"
														| "Rouge"
														| "Beige"
														| "Vert",
												})
											}
										>
											<option>Bleu</option>
											<option>Rouge</option>
											<option>Beige</option>
											<option>Vert</option>
										</Select>
									</Stack>

									<Stack spacing="4px">
										<FormLabel>Nombre de noeuds</FormLabel>
										<Input
											placeholder="Entrez une valeur numérique"
											type="number"
											onChange={(e) =>
												setCommandPost({
													...commandPost,
													knot_number: parseInt(e.target.value),
												})
											}
										></Input>
									</Stack>

									<Stack spacing="4px">
										<FormErrorMessage>
											Stock insuffisant pour cette commande.
										</FormErrorMessage>
									</Stack>
								</Stack>
							</FormControl>
						</Stack>
					</DrawerBody>

					<DrawerFooter display="flex" gap="8px">
						<Button variant="outline" onClick={onDrawerClose}>
							Annuler
						</Button>
						<Button
							colorScheme="teal"
							onClick={createCommand}
							isDisabled={!isFormValid()}
						>
							Valider la commande
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>

			{isAlertOpen && (
				<Alert
					status="success"
					position="fixed"
					right="8px"
					bottom="8px"
					display="flex"
					width="fit-content"
				>
					<AlertIcon />
					<Box>
						<AlertTitle>Nouvelle commande</AlertTitle>
						<AlertDescription>
							La commande a bien été créée, chargez les noeuds dans
							l’application
						</AlertDescription>
					</Box>
					<CloseButton
						alignSelf="flex-start"
						position="relative"
						right={-1}
						top={-1}
						onClick={onAlertClose}
					/>
				</Alert>
			)}
		</>
	)
}
