import { create } from "zustand"

export interface Client {
	id: number
	company: string
	createdAt: string
	updatedAt: string
	commandsCount: number
	lastCommandDate: string
	knotsCount: number
}

export interface ClientPost {
	company: string
}

export interface Stock {
	id: number
	size: "Petit" | "Moyen" | "Grand"
	color: "Bleu" | "Rouge" | "Beige" | "Vert"
	quantityTotal: number
	quantityAvailable: number
	quantitySold: number
	createdAt: string
	updatedAt: string
}

export interface StockPost {
	size: "Petit" | "Moyen" | "Grand"
	color: "Bleu" | "Rouge" | "Beige" | "Vert"
	quantityTotal: number
}

export interface StockUpdate {
	quantityAvailable: number
}

export interface Command {
	id: number
	client: Client
	clientId: number
	order_number: number
	knot_number: number
	remaining_knots: number
	knot_size: "Petit" | "Moyen" | "Grand"
	knot_color: "Bleu" | "Rouge" | "Beige" | "Vert"
	status: "PENDING" | "REGISTERED" | "DISABLED"
	knots: Knot[]
	createdAt: string
	updatedAt: string
}

export interface CommandView extends Omit<Command, "id" | "clientId" | "client" | "knots" | "updatedAt"> {
	key: number
	company: string
}

export interface CommandPost {
	clientId: number
	order_number: number
	knot_number: number
	knot_size: "Petit" | "Moyen" | "Grand"
	knot_color: "Bleu" | "Rouge" | "Beige" | "Vert"
}

export interface Knot {
	id: string
	size: "Petit" | "Moyen" | "Grand"
	color: "Bleu" | "Rouge" | "Beige" | "Vert"
	status: "PENDING" | "USED" | "REGISTERED" | "DISABLED"
	createdAt: string
	updatedAt: string
	commandId: number
}

export interface GiftPost {
	name: string
	description: string
	partner: string
	experienceType: string
	tags: string
	availability: string
	categories: string
	opportunities: string
	btocPrice: number
	btobPrice: number
	location: string
	spokenLanguages: string
	userId: number
}

const apiUrl = process.env.REACT_APP_API_URL

function getHeaders() {
	return {
		"Content-Type": "application/json",
		Authorization: `Bearer ${useUserStore.getState().accessToken}`,
	}
}

async function fetchApi(route: string, method: string, body?: string) {
	return new Promise((resolve, reject) => {
		fetch(`${apiUrl}/${route}`, {
			method,
			headers: getHeaders(),
			body,
		})
			.then((response) => {
				response
					.json()
					.then((json) => {
						resolve(json)
					})
					.catch((error) => {
						resolve(response)
					})
			})
			.catch((error) => reject(error))
	})
}

// /:commandId/knots/:knotId/status/:status
const useUserStore = create(() => ({
	accessToken: "",
	getClients: () => fetchApi("clients", "GET"),
	getClient: (id: number) => fetchApi(`clients/${id}`, "GET") as Promise<Client>,
	postClient: (client: ClientPost) => fetchApi("clients", "POST", JSON.stringify(client)),
	putClient: (id: string, client: ClientPost) => fetchApi(`clients/${id}`, "PUT", JSON.stringify(client)),
	deleteClient: (id: string) => fetchApi(`clients/${id}`, "DELETE"),
	getStocks: () => fetchApi("stocks", "GET"),
	postStock: (stock: StockPost) => fetchApi("stocks", "POST", JSON.stringify(stock)),
	deleteStock: (id: string) => fetchApi(`stocks/${id}`, "DELETE"),
	putStock: (id: string, stock: StockUpdate) => fetchApi(`stocks/${id}`, "PUT", JSON.stringify(stock)),
	getCommands: (order?: number): Promise<Command[]> =>
		fetchApi(`commands${order ? `?order_number=${order}` : ""}`, "GET") as Promise<Command[]>,
	postCommand: (command: CommandPost) => fetchApi("commands", "POST", JSON.stringify(command)),
	getKnot: (id: string) => fetchApi(`knots/${id}`, "GET") as Promise<Knot>,
	putKnotStatus: (knotId: string, status: string) => fetchApi(`knots/${knotId}/status/${status}`, "PUT"),
	postGift: (gift: GiftPost) => fetchApi("experiences", "POST", JSON.stringify(gift)),
	getGifts: () => fetchApi("experiences", "GET"),
}))

export default useUserStore
