import { Routes, Route, Navigate, useLocation } from "react-router-dom"

import { useAuth0 } from "@auth0/auth0-react"
import { Flex } from "@chakra-ui/react"

import Navbar from "./components/Navbar"

import Login from "./views/Login"
import Clients from "./views/Clients"
import Commands from "./views/Commands"
import CommandDetails from "./views/CommandDetails"
import Stocks from "./views/Stocks"
import Experience from "./views/Experience"

import useUserStore from "./stores/userStore"

import "./App.css"
import Gifts from "./views/Gifts"

const App = () => {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
	const location = useLocation()
	const userStore = useUserStore()

	getAccessTokenSilently().then((token) => {
		userStore.accessToken = token
	})

	if (isLoading && !location.pathname.includes("/experience"))
		return (
			<>
				<Navbar></Navbar>
				<Flex
					w="100vw"
					h="100vh"
					justifyContent="center"
					alignItems="center"
					position="absolute"
					top="0"
					left="0"
				>
					<svg className="loader" viewBox="25 25 50 50">
						<circle r="20" cy="50" cx="50"></circle>
					</svg>
				</Flex>
			</>
		)

	if (location.pathname === "/") return <Navigate to="/commands" />

	if (
		!isAuthenticated &&
		location.pathname !== "/login" &&
		location.pathname !== "/experience"
	)
		return <Navigate to="/login" />
	else if (isAuthenticated && location.pathname === "/login") return <Navigate to="/" />

	return (
		<>
			{isAuthenticated && location.pathname !== "/experience" ? (
				<Navbar></Navbar>
			) : undefined}
			<div className={location.pathname !== "/experience" ? "nestedRoot" : ""}>
				<Routes>
					<Route path="/commands" element={<Commands />} />
					<Route
						path="/commands/:company/:order"
						element={<CommandDetails />}
					/>
					<Route path="/stocks" element={<Stocks />} />
					<Route path="/clients" element={<Clients />} />
					<Route path="/login" element={<Login />} />
					<Route path="/experience" element={<Experience />} />
					<Route path="/gifts" element={<Gifts />} />
				</Routes>
			</div>
		</>
	)
}

export default App
