import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import {
	Button,
	Checkbox,
	CheckboxGroup,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	NumberInput,
	NumberInputField,
	Radio,
	RadioGroup,
	Select,
	Textarea,
	useDisclosure,
} from "@chakra-ui/react"
import { Heading } from "@chakra-ui/layout"
import { Table } from "antd"
import useUserStore, { GiftPost } from "../stores/userStore"

function CreationHeadingLabel(props: { text: string }): React.JSX.Element {
	return (
		<FormLabel color="gray.600" fontWeight="700">
			{props.text}
		</FormLabel>
	)
}

function CreationFormContainer(props: { children: React.JSX.Element }): React.JSX.Element {
	return (
		<Flex gap="16px" flexDir="column" borderRadius="16px" border="1px solid #E2E8F0" p="16px" w="100%" bg="#fff">
			{props.children}
		</Flex>
	)
}

function EuroIcon(): React.JSX.Element {
	return (
		<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.93 4.5C12.0025 4.5 13.0143 4.95975 13.7793 5.7945L14.8855 4.7805C13.8325 3.63225 12.4278 3 10.93 3C9.43153 3 8.02753 3.63225 6.97603 4.78125C6.45223 5.35395 6.044 6.02245 5.77378 6.75H3.83203V8.25H5.41753C5.38903 8.4975 5.36953 8.74725 5.36953 9C5.36953 9.25275 5.38903 9.5025 5.41753 9.75H3.83203V11.25H5.77378C6.04528 11.9775 6.44953 12.6458 6.97603 13.2195C8.02753 14.3677 9.43153 15 10.93 15C12.4278 15 13.8325 14.3677 14.8848 13.2195L13.7785 12.2055C13.0143 13.0402 12.0025 13.5 10.93 13.5C9.85753 13.5 8.84653 13.041 8.08153 12.2055C7.82472 11.9165 7.60663 11.5953 7.43278 11.25H10.582V9.75H6.93628C6.89878 9.504 6.86953 9.255 6.86953 9C6.86953 8.745 6.89878 8.496 6.93628 8.25H10.582V6.75H7.43278C7.61278 6.40725 7.81978 6.08025 8.08153 5.7945C8.84653 4.95975 9.85753 4.5 10.93 4.5Z"
				fill="black"
			/>
		</svg>
	)
}

export default function Gifts(): React.JSX.Element {
	const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure()

	const [otherEventChecked, setOtherEventChecked] = useState(false)
	const [otherLangChecked, setOtherLangChecked] = useState(false)

	const userStore = useUserStore()

	const [experiences, setExperiences] = useState([] as GiftPost[])

	useEffect(() => {
		userStore.getGifts().then((exp) => {
			setExperiences(exp as GiftPost[])
		})
	}, [])

	type Location = {
		zipCode: string
		address: string
	}

	const [locations, setLocations] = useState([
		{
			zipCode: "",
			address: "",
		},
	] as Location[])

	const [locationsQty, setLocationsQty] = useState(1)

	const [giftPost, setGiftPost] = useState({} as GiftPost)

	const [tags, setTags] = useState([] as string[])
	const [opportunities, setOpportunities] = useState([] as string[])
	const [spokenLanguages, setSpokenLanguages] = useState([] as string[])
	const [categories, setCategories] = useState([] as string[])

	const AddressesList = useCallback(() => {
		return Array.from(Array(locationsQty).keys()).map((i) => (
			<Flex gap="8px" key={"location-" + i}>
				<CreationHeadingLabel text={`Lieux n°${i + 1}`} />
				<Input
					type="text"
					placeholder="Adresse"
					defaultValue={locations[i].address}
					onBlur={(e) => {
						const l = [...locations]
						l[i].address = e.target.value
						setLocations(l)
					}}
				/>
				<Input
					type="text"
					placeholder="Code postal"
					defaultValue={locations[i].zipCode}
					onBlur={(e) => {
						const l = [...locations]
						l[i].zipCode = e.target.value
						setLocations(l)
					}}
				/>
			</Flex>
		))
	}, [locationsQty])

	const checkHandl = (e: ChangeEvent, array: string[]) => {
		const arrayCopy = [...array]
		const value = e.target.parentElement?.querySelector("span+span")?.textContent ?? ""
		if (array.includes(value)) {
			const index = array.indexOf(value)
			arrayCopy.splice(index, 1)
		} else {
			arrayCopy.push(value)
		}
		return arrayCopy
	}

	return (
		<>
			<Flex direction="column" gap="16px">
				<Flex justifyContent="space-between" alignItems="center">
					<Heading>Liste des cadeaux catalogue</Heading>
					<Button colorScheme="teal" size="sm" onClick={() => onDrawerOpen()}>
						Ajouter un cadeau
					</Button>
				</Flex>

				<Table columns={[]} dataSource={[]} className="Table"></Table>

				<Drawer
					isOpen={isDrawerOpen}
					placement="right"
					onClose={function () {
						onDrawerClose()
					}}
					size="2xl"
				>
					<DrawerOverlay />
					<DrawerContent bgColor="#FAFAFA">
						<DrawerCloseButton />
						<DrawerHeader>Création d'expérience cadeau</DrawerHeader>

						<DrawerBody display="flex" flexDir="column" gap="56px">
							<Flex gap="16px" flexDir="column">
								<Heading size="sm">Informations générales</Heading>

								<Flex gap="16px" w="100%" alignItems="start">
									<CreationFormContainer>
										<>
											<FormControl>
												<CreationHeadingLabel text="Nom de l'expérience" />
												<Input
													type="text"
													onBlur={(e) => {
														setGiftPost({
															...giftPost,
															name: e.target.value,
														})
													}}
												/>
											</FormControl>
											<FormControl>
												<CreationHeadingLabel text="Description" />
												<Textarea
													onBlur={(e) => {
														setGiftPost({
															...giftPost,
															description: e.target.value,
														})
													}}
												/>
											</FormControl>
											<FormControl>
												<CreationHeadingLabel text="Partenaire" />
												<Input
													type="text"
													onBlur={(e) => {
														setGiftPost({
															...giftPost,
															partner: e.target.value,
														})
													}}
												/>
											</FormControl>
										</>
									</CreationFormContainer>

									<CreationFormContainer>
										<FormControl>
											<CreationHeadingLabel text="Image du produit" />
											<Input type="text" isDisabled={true} />
										</FormControl>
									</CreationFormContainer>
								</Flex>
							</Flex>

							<Flex gap="16px" flexDir="column">
								<Heading size="sm">Catégorisasion</Heading>

								<Flex gap="16px" w="100%" alignItems="stretch">
									<CreationFormContainer>
										<FormControl>
											<CreationHeadingLabel text="Type de cadeau" />
											<RadioGroup
												display="flex"
												flexDir="column"
												gap="12px"
												onChange={(e) => {
													setGiftPost({
														...giftPost,
														experienceType: e,
													})
												}}
											>
												<Radio value="Digital Gifts © (Cadeaux numériques)">
													Digital Gifts © (Cadeaux numériques)
												</Radio>
												<Radio value="Activité en ligne">Activité en ligne</Radio>
												<Radio value="Activité en présentiel">Activité en présentiel</Radio>
												<Radio value="Séjours">Séjours</Radio>
											</RadioGroup>
										</FormControl>
									</CreationFormContainer>

									<CreationFormContainer>
										<>
											<FormControl>
												<CreationHeadingLabel text="Tags" />
												<CheckboxGroup>
													<Flex flexDir="column" gap="12px">
														<Checkbox onChange={(e) => setTags(checkHandl(e, tags))}>
															Exclusivité Présent
														</Checkbox>
														<Checkbox onChange={(e) => setTags(checkHandl(e, tags))}>
															Choix Responsable
														</Checkbox>
													</Flex>
												</CheckboxGroup>
											</FormControl>
											<FormControl>
												<CreationHeadingLabel text="Disponibilité" />
												<RadioGroup
													display="flex"
													flexDir="column"
													gap="12px"
													onChange={(e) => {
														setGiftPost({
															...giftPost,
															availability: e,
														})
													}}
												>
													<Radio value="Disponible immédiatement">
														Disponible immédiatement
													</Radio>
													<Radio value="Réservation par le destinataire">
														Réservation par le destinataire
													</Radio>
												</RadioGroup>
											</FormControl>
										</>
									</CreationFormContainer>
								</Flex>

								<Flex gap="16px" w="100%" alignItems="stretch">
									<CreationFormContainer>
										<FormControl>
											<CreationHeadingLabel text="Catégories" />
											<CheckboxGroup>
												<Flex flexDir="column" gap="12px">
													<Checkbox
														onChange={(e) => setCategories(checkHandl(e, categories))}
													>
														Expériences & Séjours
													</Checkbox>
													<Checkbox
														onChange={(e) => setCategories(checkHandl(e, categories))}
													>
														Savoir-faire & Arts
													</Checkbox>
													<Checkbox
														onChange={(e) => setCategories(checkHandl(e, categories))}
													>
														Bien-être physique & Santé mentale
													</Checkbox>
													<Checkbox
														onChange={(e) => setCategories(checkHandl(e, categories))}
													>
														Talents & Créativité
													</Checkbox>
													<Checkbox
														onChange={(e) => setCategories(checkHandl(e, categories))}
													>
														Initiations & Formations
													</Checkbox>
													<Checkbox
														onChange={(e) => setCategories(checkHandl(e, categories))}
													>
														Accompagnement moments de vie
													</Checkbox>
													<Checkbox
														onChange={(e) => setCategories(checkHandl(e, categories))}
													>
														Écologie & Climat
													</Checkbox>
												</Flex>
											</CheckboxGroup>
										</FormControl>
									</CreationFormContainer>

									<CreationFormContainer>
										<>
											<FormControl>
												<CreationHeadingLabel text="Occasions" />
												<CheckboxGroup>
													<Flex gap="40px">
														<Flex flexDir="column" gap="12px">
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Pot de départ
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Pot d’arrivée
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Lancement d’offres
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Anniversaire d’un collaborateur
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Anniversaire d’entreprise
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Évènement d’entreprise
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Séminaire d’entreprise
															</Checkbox>
														</Flex>
														<Flex flexDir="column" gap="12px">
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Teambuilding
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Fêtes de fin d’année
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Remerciement
															</Checkbox>
															<Checkbox
																onChange={(e) =>
																	setOpportunities(checkHandl(e, opportunities))
																}
															>
																Levée de fonds
															</Checkbox>
															<Checkbox
																onChange={(e) => setOtherEventChecked(e.target.checked)}
															>
																Autre évènement
															</Checkbox>
															<Input
																type="text"
																placeholder="Préciser autre"
																isDisabled={!otherEventChecked}
																onBlur={(e) => {
																	const arrayCopy = [...categories]
																	const value = e.target.value ?? ""
																	if (categories.includes(value)) {
																		const index = categories.indexOf(value)
																		arrayCopy.splice(index, 1)
																	} else {
																		arrayCopy.push(value)
																	}
																	setOpportunities(arrayCopy)
																}}
															/>
														</Flex>
													</Flex>
												</CheckboxGroup>
											</FormControl>
										</>
									</CreationFormContainer>
								</Flex>
							</Flex>

							<Flex gap="16px" flexDir="column">
								<Heading size="sm">Prix HT et Attribution</Heading>

								<Flex gap="16px" w="100%" alignItems="start">
									<CreationFormContainer>
										<>
											<Checkbox fontWeight="700">BtoC HT</Checkbox>
											<Flex gap="8px">
												<Select width="60%">
													<option>Prix unique</option>
													<option>Prix / personne</option>
												</Select>
												<InputGroup w="40%">
													<NumberInput
														w="100%"
														onBlur={(e) => {
															setGiftPost({
																...giftPost,
																btocPrice: parseInt(e.target.value),
															})
														}}
													>
														<NumberInputField />
														<InputRightElement pointerEvents="none">
															<EuroIcon />
														</InputRightElement>
													</NumberInput>
												</InputGroup>
											</Flex>
										</>
									</CreationFormContainer>

									<CreationFormContainer>
										<>
											<Checkbox fontWeight="700">BtoB HT</Checkbox>
											<Flex gap="8px">
												<Select width="60%">
													<option>Prix unique</option>
													<option>Prix / personne</option>
												</Select>
												<InputGroup w="40%">
													<NumberInput
														w="100%"
														onBlur={(e) => {
															setGiftPost({
																...giftPost,
																btobPrice: parseInt(e.target.value),
															})
														}}
													>
														<NumberInputField />
														<InputRightElement pointerEvents="none">
															<EuroIcon />
														</InputRightElement>
													</NumberInput>
												</InputGroup>
											</Flex>
										</>
									</CreationFormContainer>
								</Flex>
							</Flex>

							<Flex gap="16px" flexDir="column">
								<Heading size="sm">Localisation et langue</Heading>

								<Flex gap="16px">
									<Flex gap="16px" w="50%" alignItems="start">
										<CreationFormContainer>
											<>
												<CreationHeadingLabel text="Adresse complète" />
												<AddressesList />
												<Button
													width="fit-content"
													onClick={() => {
														setLocations([
															...locations,
															{
																zipCode: "",
																address: "",
															},
														])
														setLocationsQty(locationsQty + 1)
													}}
												>
													Ajouter une localisation
												</Button>
											</>
										</CreationFormContainer>
									</Flex>

									<Flex gap="16px" w="50%" alignItems="start">
										<CreationFormContainer>
											<>
												<CreationHeadingLabel text="Langue parlé" />
												<CheckboxGroup
													onChange={(e) => {
														setGiftPost({
															...giftPost,
															spokenLanguages: e.toString(),
														})
													}}
												>
													<Checkbox
														onChange={(e) =>
															setSpokenLanguages(checkHandl(e, spokenLanguages))
														}
													>
														Français
													</Checkbox>
													<Checkbox
														onChange={(e) =>
															setSpokenLanguages(checkHandl(e, spokenLanguages))
														}
													>
														Anglais
													</Checkbox>
													<Checkbox
														onChange={(e) =>
															setSpokenLanguages(checkHandl(e, spokenLanguages))
														}
													>
														Espagnol
													</Checkbox>
													<Checkbox
														onChange={(e) =>
															setSpokenLanguages(checkHandl(e, spokenLanguages))
														}
													>
														Italien
													</Checkbox>
													<Checkbox
														onChange={(e) =>
															setSpokenLanguages(checkHandl(e, spokenLanguages))
														}
													>
														Allemand
													</Checkbox>
													<Checkbox
														onChange={(e) =>
															setSpokenLanguages(checkHandl(e, spokenLanguages))
														}
													>
														Portugais
													</Checkbox>
													<Checkbox onChange={(e) => setOtherLangChecked(e.target.checked)}>
														Autre
													</Checkbox>
													<Input
														type="text"
														placeholder="Préciser autre"
														isDisabled={!otherLangChecked}
														onBlur={(e) => {
															setGiftPost({
																...giftPost,
																spokenLanguages: e.target.value,
															})
														}}
													/>
												</CheckboxGroup>
											</>
										</CreationFormContainer>
									</Flex>
								</Flex>
							</Flex>
						</DrawerBody>

						<DrawerFooter display="flex" gap="8px">
							<Button variant="outline" onClick={onDrawerClose}>
								Annuler
							</Button>
							<Button
								colorScheme="green"
								onClick={() => {
									const giftToPost = {
										...giftPost,
										location: locations.map((x) => `${x.address} ${x.zipCode}\n`).join(""),
										spokenLanguages: spokenLanguages.join(","),
										categories: categories.join(","),
										opportunities: opportunities.join(","),
										tags: tags.join(","),
										userId: -1,
									}

									userStore.postGift(giftToPost)
								}}
							>
								Confirmer et créer
							</Button>
						</DrawerFooter>
					</DrawerContent>
				</Drawer>
			</Flex>
		</>
	)
}
