import { useAuth0 } from "@auth0/auth0-react"
import type { ReactNode } from "react"

export default function LogoutButton(props: { children: ReactNode }) {
	const { logout } = useAuth0()

	return (
		<span
			onClick={() =>
				logout({ logoutParams: { returnTo: window.location.origin } })
			}
		>
			{props.children}
		</span>
	)
}
