import { useAuth0 } from "@auth0/auth0-react"
import {
	Avatar,
	Button,
	Flex,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SkeletonCircle,
	Tooltip,
	Wrap,
	WrapItem,
	useDisclosure,
} from "@chakra-ui/react"
import { Link as DomLink, useLocation } from "react-router-dom"

import LogoutComponent from "./LogoutComponent"

function LogoPresent() {
	return <img src="/logo.svg" alt="logo" />
}

function NavLink(path: string, text: string) {
	const location = useLocation()
	const isActive = location.pathname === path

	return (
		<Link
			as={DomLink}
			to={path}
			fontWeight={isActive ? "bold" : "normal"}
			color={isActive ? "teal" : "chakra-body-text._dark"}
		>
			{text}
		</Link>
	)
}

export default function Navbar(): JSX.Element {
	const { user, isLoading } = useAuth0()
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<Flex
			w="full"
			maxW="100%"
			p="4"
			gap={8}
			boxShadow="sm"
			alignItems="center"
			bg="white"
		>
			<LogoPresent />

			<Wrap spacing="8">
				<WrapItem>{NavLink("/commands", "Commandes")}</WrapItem>
				<WrapItem>{NavLink("/stocks", "Stocks")}</WrapItem>
				<WrapItem>{NavLink("/clients", "Clients")}</WrapItem>
				<WrapItem>{NavLink("/gifts", "Cadeaux")}</WrapItem>
			</Wrap>

			<Button variant="outline" colorScheme="teal" ml="auto">
				Ajouter une commande
			</Button>

			<Tooltip label="Se déconnecter">
				<>
					{isLoading ? (
						<SkeletonCircle size="12" />
					) : (
						<Avatar
							name={user?.given_name}
							src={user?.picture}
							onClick={onOpen}
							_hover={{ cursor: "pointer", boxShadow: "md" }}
						/>
					)}
				</>
			</Tooltip>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Déconnexion</ModalHeader>
					<ModalCloseButton />
					<ModalBody>Êtes-vous sûr de vouloir vous déconnecter ?</ModalBody>
					<ModalFooter display="flex" gap="4">
						<Button colorScheme="red">
							<LogoutComponent>Se déconnecter</LogoutComponent>
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	)
}
