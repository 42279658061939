import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"

import "./Experience.css"
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Badge,
	Box,
	CloseButton,
	Flex,
	Heading,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { CopyIcon } from "@chakra-ui/icons"
import useUserStore from "../stores/userStore"
import { Knot } from "../stores/userStore"

export default function Experience() {
	const [searchParams] = useSearchParams()
	const [id] = useState(searchParams.get("id") ?? "ID introuvable")
	const [knot, setKnot] = useState({} as Knot)

	const {
		isOpen: isAlertOpen,
		onOpen: onAlertOpen,
		onClose: onAlertClose,
	} = useDisclosure()

	const userStore = useUserStore()

	useEffect(() => {
		userStore
			.getKnot(id)
			.then((knot) => {
				setKnot(knot)
			})
			.catch((err) => {
				console.error(err)
			})
	}, [userStore, id])

	function getBadge(status: string): JSX.Element | undefined {
		switch (status) {
			case "PENDING":
				return <Badge colorScheme="blue">EN ATTENTE</Badge>
			case "REGISTERED":
				return <Badge colorScheme="green">ENREGISTRÉ</Badge>
			case "DISABLED":
				return <Badge colorScheme="red">DÉSACTIVÉ</Badge>
			default:
				return <Badge colorScheme="gray">INCONNU</Badge>
		}
	}

	return (
		<>
			<Flex
				flexDir="column"
				justifyContent="center"
				alignItems="center"
				minHeight="100vh"
				gap="40px"
				className="experience"
			>
				<Heading textAlign="center">
					<img src="/knot.svg" alt="présent"></img>
				</Heading>
				<Flex
					alignItems="center"
					justifyContent="center"
					backgroundColor="#00000015"
					padding="12px 14px"
					rounded="4px"
					gap="8px"
					onClick={() => navigator.clipboard.writeText(id)}
				>
					<Text fontSize="small" color="#fff">
						{id}
					</Text>
					<CopyIcon
						cursor="pointer"
						color="white"
						onClick={() => {
							onAlertOpen()
							setTimeout(() => {
								onAlertClose()
							}, 5000)
						}}
					></CopyIcon>
				</Flex>
				{/* <Text color="whiteAlpha.700">
					Le statut du noeud est: {getBadge(knot.status)}
				</Text> */}
			</Flex>

			{isAlertOpen && (
				<Alert
					status="success"
					position="fixed"
					right="8px"
					bottom="8px"
					display="flex"
					width="fit-content"
				>
					<AlertIcon />
					<Box>
						<AlertTitle>ID Copié !</AlertTitle>
						<AlertDescription>
							L'ID du noeud a bien été copié dans le presse-papier.
						</AlertDescription>
					</Box>
					<CloseButton
						alignSelf="flex-start"
						position="relative"
						right={-1}
						top={-1}
						onClick={onAlertClose}
					/>
				</Alert>
			)}
		</>
	)
}
