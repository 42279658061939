import React from "react"
import ReactDOM from "react-dom/client"
import { HashRouter } from "react-router-dom"

import { Auth0Provider } from "@auth0/auth0-react"
import { ChakraProvider } from "@chakra-ui/react"
import { ConfigProvider as AntdProvider } from "antd"
import frFR from "antd/locale/fr_FR"

import App from "./App"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
	<React.StrictMode>
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: process.env.REACT_APP_AUTH0_AUDIENCE,
			}}
		>
			<HashRouter>
				<AntdProvider locale={frFR}>
					<ChakraProvider>
						<App />
					</ChakraProvider>
				</AntdProvider>
			</HashRouter>
		</Auth0Provider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
