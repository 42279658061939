import { useEffect, useState } from "react"

import {
	Flex,
	Heading,
	Button,
	Select,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Input,
	FormControl,
	FormLabel,
	Alert,
	AlertIcon,
	Box,
	AlertTitle,
	AlertDescription,
	CloseButton,
	Stack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react"

import { Table } from "antd"
import { ColumnsType } from "antd/es/table"

import useUserStore, { type Stock } from "../stores/userStore"

export default function Stocks() {
	const userStore = useUserStore()

	const {
		isOpen: isDrawerOpen,
		onOpen: onDrawerOpen,
		onClose: onDrawerClose,
	} = useDisclosure()
	const {
		isOpen: isAlertOpen,
		onOpen: onAlertOpen,
		onClose: onAlertClose,
	} = useDisclosure()
	const {
		isOpen: isDeleteModalOpen,
		onOpen: onDeleteModalOpen,
		onClose: onDeleteModalClose,
	} = useDisclosure()

	const [isEditing, setIsEditing] = useState(false)
	const [quantityAvailable, setQuantityAvailable] = useState(0)

	const [stocks, setStocks] = useState([] as Stock[])
	const [size, setSize] = useState("")
	const [color, setColor] = useState("")
	const [quantity, setQuantity] = useState(0)
	const [stockId, setStockId] = useState("")

	function isFormValid() {
		if (isEditing) {
			return quantityAvailable >= 0
		}

		return (
			["Petit", "Moyen", "Grand"].includes(size) &&
			["Bleu", "Rouge", "Beige", "Vert"].includes(color) &&
			quantity > 0
		)
	}

	function updateStocks() {
		userStore.getStocks().then((data: any) => {
			setStocks(
				data.map((x: Stock) => ({
					key: x.id,
					size: x.size,
					color: x.color,
					quantityTotal: x.quantityTotal,
					quantitySold: x.quantitySold,
					quantityAvailable: x.quantityAvailable,
				}))
			)
		})
	}

	function createStock() {
		userStore
			.postStock({
				size: size as "Petit" | "Moyen" | "Grand",
				color: color as "Bleu" | "Rouge" | "Beige" | "Vert",
				quantityTotal: quantity,
			})
			.then(() => {
				updateStocks()
				onDrawerClose()

				onAlertOpen()
				setTimeout(onAlertClose, 5000)

				setSize("")
				setColor("")
				setQuantity(0)
			})
			.catch((err) => {
				console.error(err)
			})
	}

	function editStock() {
		userStore
			.putStock(stockId, { quantityAvailable })
			.then(() => {
				updateStocks()
				onDrawerClose()
				setIsEditing(false)
			})
			.catch((err) => {
				console.error(err)
			})
	}

	useEffect(updateStocks, [userStore])

	const columns: ColumnsType<any> = [
		{
			title: "Taille",
			dataIndex: "size",
			key: "size",
			sorter: (a: Stock, b: Stock) => a.size.localeCompare(b.size),
		},
		{
			title: "Couleur",
			dataIndex: "color",
			key: "color",
			sorter: (a: Stock, b: Stock) => a.color.localeCompare(b.color),
		},
		{
			title: "Total produit",
			dataIndex: "quantityTotal",
			key: "quantityTotal",
			sorter: (a: Stock, b: Stock) => a.quantityTotal - b.quantityTotal,
		},
		{
			title: "Total vendu",
			dataIndex: "quantitySold",
			key: "quantitySold",
			sorter: (a: Stock, b: Stock) => a.quantitySold - b.quantitySold,
		},
		{
			title: "Total restant en stock",
			dataIndex: "quantityAvailable",
			key: "quantityAvailable",
			sorter: (a: Stock, b: Stock) => a.quantityAvailable - b.quantityAvailable,
		},
		{
			title: "Options",
			render: (text, record, index) => (
				<Select
					placeholder="Ouvrir"
					size="sm"
					w="24"
					onChange={(event) => {
						setStockId(record.key)
						if (event.target.value === "Supprimer le stock") {
							onDeleteModalOpen()
						} else {
							setQuantity(record.quantityAvailable)
							setQuantityAvailable(record.quantityAvailable)
							setIsEditing(true)
							onDrawerOpen()
						}
						event.target.selectedIndex = 0
					}}
				>
					<option>Modifier le stock</option>
					<option>Supprimer le stock</option>
				</Select>
			),
		},
	]

	return (
		<>
			<Flex direction="column" gap="16px">
				<Flex justifyContent="space-between" alignItems="center">
					<Heading>Stock des noeuds</Heading>
					<Button colorScheme="teal" size="sm" onClick={onDrawerOpen}>
						Ajouter du stock
					</Button>
				</Flex>

				<Table columns={columns} dataSource={stocks} className="Table"></Table>
			</Flex>

			<Drawer
				isOpen={isDrawerOpen}
				placement="right"
				onClose={() => {
					setIsEditing(false)
					onDrawerClose()
				}}
				size="lg"
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						{isEditing ? "Modifier un" : "Ajouter un nouveau"} stock
					</DrawerHeader>

					<DrawerBody>
						<FormControl display="flex" flexDir="column" gap="16px">
							{!isEditing ? (
								<>
									<Stack spacing="4px">
										<FormLabel>Taille des noeuds</FormLabel>
										<Select
											placeholder="Sélectionnez la taille des noeuds"
											onChange={(e) => setSize(e.target.value)}
										>
											<option>Petit</option>
											<option>Moyen</option>
											<option>Grand</option>
										</Select>
									</Stack>

									<Stack spacing="4px">
										<FormLabel>Couleur des noeuds</FormLabel>
										<Select
											placeholder="Sélectionnez la couleur des noeuds"
											onChange={(e) => setColor(e.target.value)}
										>
											<option>Bleu</option>
											<option>Rouge</option>
											<option>Beige</option>
											<option>Vert</option>
										</Select>
									</Stack>
								</>
							) : undefined}

							<Stack spacing="4px">
								<FormLabel>
									Nombre de noeuds{" "}
									{isEditing ? "restants en stock" : undefined}
								</FormLabel>
								<Input
									placeholder="Entrez une valeur numérique"
									type="number"
									onChange={function (event) {
										const quantity = parseInt(event.target.value)
										setQuantity(quantity)
										setQuantityAvailable(quantity)
									}}
									value={isEditing ? quantityAvailable : undefined}
								></Input>
							</Stack>
						</FormControl>
					</DrawerBody>

					<DrawerFooter display="flex" gap="8px">
						<Button variant="outline" onClick={onDrawerClose}>
							Annuler
						</Button>
						<Button
							colorScheme="teal"
							onClick={() => {
								if (isEditing) {
									editStock()
								} else {
									createStock()
								}
							}}
							isDisabled={!isFormValid()}
						>
							{isEditing ? "Modifier" : "Ajouter"} le stock
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>

			<Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Suppression du stock</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						Êtes-vous vraiment sûr de vouloir supprimer le stock de noeuds
						{size} {color} ?
					</ModalBody>
					<ModalFooter display="flex" gap="4">
						<Button colorScheme="gray" onClick={onDeleteModalClose}>
							Annuler
						</Button>
						<Button
							colorScheme="red"
							onClick={() => {
								userStore.deleteStock(stockId).then(updateStocks)
								onDeleteModalClose()
							}}
						>
							Supprimer
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{isAlertOpen && (
				<Alert
					status="success"
					position="fixed"
					right="8px"
					bottom="8px"
					display="flex"
					width="fit-content"
				>
					<AlertIcon />
					<Box>
						<AlertTitle>Nouveau stock de noeuds </AlertTitle>
						<AlertDescription>
							Le stock a bien été mis à jour
						</AlertDescription>
					</Box>
					<CloseButton
						alignSelf="flex-start"
						position="relative"
						right={-1}
						top={-1}
						onClick={onAlertClose}
					/>
				</Alert>
			)}
		</>
	)
}
