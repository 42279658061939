import { useEffect, useState } from "react"

import { useParams } from "react-router"

import { Badge, Box, Heading } from "@chakra-ui/layout"
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	useDisclosure,
	Button,
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	CloseButton,
} from "@chakra-ui/react"

import { Table } from "antd"
import { ColumnsType } from "antd/es/table"

import useUserStore, { Knot } from "../stores/userStore"

export default function CommandDetails() {
	const params = useParams() as { company: string; order: string }

	const userStore = useUserStore()

	const [knots, setKnots] = useState([] as Knot[])

	const [knotToDeactivate, setKnotToDeactivate] = useState({} as Knot)
	const {
		isOpen: isModalOpen,
		onOpen: onModalOpen,
		onClose: onModalClose,
	} = useDisclosure()
	const {
		isOpen: isAlertOpen,
		onOpen: onAlertOpen,
		onClose: onAlertClose,
	} = useDisclosure()

	function updateCommands() {
		userStore.getCommands(parseInt(params.order)).then((data) => {
			const newKnots = data
				.map((x) => x.knots.map((y) => ({ ...y, key: y.id })))
				.flat()
				.map((x, idx) => Object.assign(x, { index: idx+1 }))
			setKnots(newKnots)
		})
	}

	useEffect(updateCommands, [userStore, params, setKnots])

	const columns: ColumnsType<any> = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			sorter: (a, b) => a.id.localeCompare(b.id),
		},
		{
			title: "Taille des noeuds",
			dataIndex: "size",
			key: "size",
			sorter: (a, b) => a.size - b.size,
		},
		{
			title: "Couleur des noeuds",
			dataIndex: "color",
			key: "color",
			sorter: (a, b) => a.color.localeCompare(b.color),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text, record, index) => {
				switch (record.status) {
					case "PENDING":
						return <Badge colorScheme="blue">EN ATTENTE</Badge>
					case "USED":
						return <Badge colorScheme="yellow">UTILISÉ</Badge>
					case "REGISTERED":
						return <Badge colorScheme="green">ENREGISTRÉ</Badge>
					case "DISABLED":
						return <Badge colorScheme="red">DÉSACTIVÉ</Badge>
				}
			},
			sorter: (a, b) => a.status.localeCompare(b.status),
		},
		{
			title: "Index",
			dataIndex: "index",
			key: "index"
		},
		{
			title: "Options",
			render: (text: any, record: any) => (
				<Select
					placeholder="Ouvrir"
					size="sm"
					w="24"
					onChange={(event) => {
						switch (event.target.value) {
							case "Copier":
								onAlertOpen()
								setTimeout(onAlertClose, 5000)
								navigator.clipboard.writeText(record.id)
								break
							case "Ouvrir":
								window.open(`/#/experience?id=${record.id}`)
								break
							case "Désactiver":
								setKnotToDeactivate(record)
								onModalOpen()
								break
						}
						event.target.selectedIndex = 0
					}}
				>
					<option>Copier</option>
					<option>Ouvrir</option>
					<option>Désactiver</option>
				</Select>
			),
		},
	]

	return (
		<>
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink href="#/commands">Liste des commandes</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>
						{params.company} {params.order}
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Heading>
				{params.company} {params.order}
			</Heading>

			<Table columns={columns} dataSource={knots} className="Table"></Table>

			<Modal isOpen={isModalOpen} onClose={onModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Désactivation du noeud</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						Êtes-vous vraiment sûr de vouloir désactiver le noeud{" "}
						{knotToDeactivate.id} ({knotToDeactivate.color},{" "}
						{knotToDeactivate.size}) ?
					</ModalBody>
					<ModalFooter display="flex" gap="4">
						<Button colorScheme="gray" onClick={onModalClose}>
							Annuler
						</Button>
						<Button
							colorScheme="red"
							onClick={() => {
								userStore
									.putKnotStatus(knotToDeactivate.id, "DISABLED")
									.then(updateCommands)
								onModalClose()
							}}
						>
							Désactiver
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{isAlertOpen && (
				<Alert
					status="success"
					position="fixed"
					right="8px"
					bottom="8px"
					display="flex"
					width="fit-content"
				>
					<AlertIcon />
					<Box>
						<AlertTitle>ID Copié !</AlertTitle>
						<AlertDescription>
							L'ID du noeud a bien été copié dans le presse-papier.
						</AlertDescription>
					</Box>
					<CloseButton
						alignSelf="flex-start"
						position="relative"
						right={-1}
						top={-1}
						onClick={onAlertClose}
					/>
				</Alert>
			)}
		</>
	)
}
